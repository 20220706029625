import { useEffect, useState } from "react";
import MatchCard from "../matchCard";
import MatchStyleWrapper from "./Match.style";
import { useModal } from "../../../../utils/ModalContext";
import http from "../../../../utils/Http";
import exampleLogo from "../../../../assets/images/match/mainz.png";
import exampleLeague from "../../../../assets/images/match/league.png";
import lock from "../../../../assets/images/icon/lock.png";
import { format, subWeeks, startOfWeek, endOfWeek, addWeeks} from "date-fns";
const Match = () => {
  const { emailLoggedIn } = useModal();

  const [leagues, setLeagues] = useState([]);
  const [matchesData, setMatchesData] = useState([]);

  const transformEndpointData = (endpointData) => {
    const matchesByLeague = {};

    endpointData.data.forEach((match) => {
      const prediction = match.__prediction__;
      const homeTeam = match.__HomeTeam__;
      const awayTeam = match.__AwayTeam__;
      const leagueName = match.LeagueID;

      if (!matchesByLeague[leagueName]) {
        matchesByLeague[leagueName] = [];
      }

      matchesByLeague[leagueName].push({
        id: match.MatchID,
        league: leagueName,
        match: match.MatchDate.split("T")[0],
        team1: homeTeam.TeamName,
        team2: awayTeam.TeamName,
        team1_image: homeTeam.TeamLogoURL || null,
        team2_image: awayTeam.TeamLogoURL || null,
        home: {
          percent: prediction.HomeWinPercentage,
          value: parseFloat(prediction.OddsHomeWin),
        },
        draw: {
          percent: prediction.DrawPercentage,
          value: parseFloat(prediction.OddsDraw),
        },
        away: {
          percent: prediction.AwayWinPercentage,
          value: parseFloat(prediction.OddsAwayWin),
        },
        bet: prediction.PredictedOutcome,
      });
    });

    return matchesByLeague;
  };

  const currentDate = new Date();
  const startOfRange = currentDate; // Directly use currentDate, as the range starts today
  const endOfNextWeek = endOfWeek(addWeeks(currentDate, 1), { weekStartsOn: 1 });

  async function fetchLeagues() {
    try {
      const response = await http.get(
        "https://rektbookie-api.as.r.appspot.com/v1/league"
      );
      setLeagues(response.data.data);
    } catch (error) {
      console.error("Error fetching league data:", error);
    }
  }

  async function fetchMatchesByLeague(leagueName) {
    try {
      const response = await http.get(
        `https://rektbookie-api.as.r.appspot.com/v1/matches/${leagueName}`
      );
      const transformedData = transformEndpointData(response.data);
      setMatchesData(transformedData);
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  }

  useEffect(() => {
    // Fetch data from the endpoint when the component mounts
    fetchLeagues();
  }, [emailLoggedIn]); // Empty dependency array ensures the effect runs once when the component mounts

  useEffect(() => {
    // Fetch data from the endpoint when the component mounts
    if (leagues.length > 0) {
      // Fetch matches for the first league by default
      fetchMatchesByLeague(leagues[0].LeagueName);
    }
  }, [leagues]);

  return (
    <MatchStyleWrapper>
      <div className="bithu_match_sect v1_match_sect" id="match">
        <div className="v1_match_overlay">
          <div className="container">
            <h2 className="headline text-uppercase">{"match anaylsis"}</h2>
            <h3 className="subtitle klavika">
              {"here’s our ai takes on the on-going match!"}
            </h3>
          </div>
          {leagues.map((league) => (
            <div className="container-xl rbke-container mb-4">
              <div className="d-flex flex-column align-items-center">
                <img
                  src={league.LeagueLogoURL}
                  alt="exampleLeague"
                  className="rbke-league d-flex"
                  lazy="loading"
                />
                <h2 className="klavika text-lowercase text-center mt-2">
                  Matches On
                </h2>
                <h2 className="color-primary klavika text-lowercase text-center mb-5 date-match">
                  {`${format(startOfRange, "dd MMM yyyy")} - ${format(
                    endOfNextWeek,
                    "dd MMM yyyy"
                  )}`}
                </h2>
              </div>

              <div className="row justify-content-center gap-3">
                <h2 style={{ textAlign: "center" }}>{league.LeagueName}</h2>

                {/* <div key={league.LeagueID}> */}
                {matchesData[league.LeagueID]?.map((match) => (
                  <div className="card card-rbke" key={match.id}>
                    <div className="row align-items-center mb-3">
                      <div className="col-4 text-center">
                        <img
                          src={match.team1_image}
                          alt="example"
                          className="rbke-match"
                          lazy="loading"
                        />
                      </div>
                      <div className="col-4 text-center">
                        <h4 className="text-lowercase color-primary">
                          {match.match}
                        </h4>
                      </div>
                      <div className="col-4">
                        <img
                          src={match.team2_image}
                          alt="example"
                          className="rbke-match"
                          lazy="loading"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <h4 className="col-5 text-uppercase text-center">
                        {match.team1}
                      </h4>
                      <h4 className="col-2 text-center">VS</h4>
                      <h4 className="col-5 text-uppercase text-center">
                        {match.team2}
                      </h4>
                    </div>
                    <div className="row detail">
                      <div className="col-4">
                        <h4>HOME</h4>
                        <p>{`${match.home.percent}%`}</p>
                        <p className="font-italic">{`${match.home.value}`}</p>
                      </div>
                      <div className="col-4">
                        <h4>DRAW</h4>
                        <p>{`${match.draw.percent}%`}</p>
                        <p className="font-italic">{`${match.draw.value}`}</p>
                      </div>
                      <div className="col-4">
                        <h4>AWAY</h4>
                        <p>{`${match.away.percent}%`}</p>
                        <p className="font-italic">{`${match.away.value}`}</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <h4 className="text-uppercase">
                        ai picks &nbsp;
                        <span className="color-primary">{`${match.bet} to win`}</span>
                      </h4>
                    </div>
                  </div>
                ))}
                {/* </div> */}
                {!emailLoggedIn && (
                  <div className="card card-rbke hidden">
                    <div className="row justify-content-center">
                      <img src={lock} alt="lock" />
                      <h4 className="color-primary mt-5">member only</h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </MatchStyleWrapper>
  );
};

export default Match;
