import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SectionTitle from "../../../../common/sectionTitle";
import HistoryStyleWrapper from "./History.style";
import ballImg from "../../../../assets/images/icon/ball.png";
import thinkImg from "../../../../assets/images/rektbookie.webp";
import data from "../history/data";
import { FaAngleRight } from "react-icons/fa";
import stampRbke from "../../../../assets/images/stamp-rbke.png";
import exampleTeam from "../../../../assets/images/match/mainz.png";

const History = () => {

  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get('https://rektbookie-api.as.r.appspot.com/v1/lastmatchresult');
        setMatches(response.data.data); // Assuming the data of interest is in the 'data' field
      } catch (error) {
        console.error('Failed to fetch matches:', error);
        // Handle error (e.g., show a notification or a message to the user)
      }
    };

    fetchMatches();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).toUpperCase();
  };


  return (
    <HistoryStyleWrapper className="bithu_about_sect" id="about">
      <div className="container">
        <div className="d-flex flex-column align-items-center mb-5">
          <img
            src={stampRbke}
            alt="stamp"
            className="rbke-league mb-5"
            lazy="loading"
          />
          <h3 className="subtitle klavika">{"what's this?"}</h3>
          <p className="text-center description">
            It’s the <strong>Rektbookie’s stamp of approval!</strong> A stamp
            that we grant to matches with slightly lower confidence level bus
            still consider favorable. A badge that marks “honorable mentions”, a
            hot take of our AI. Well suited for those who are feeling lucky and
            adventurous, or just want to try something new!
          </p>
        </div>

        <h2 className="headline text-uppercase">{"past predictions"}</h2>
        <h3 className="subtitle klavika">{"all record of our past matches"}</h3>
      </div>

      <div className="container-xl rbke-container mt-5">
        <div className="row mb-3 rbke-header">
          <h3 className="col-3 klavika">match date</h3>
          <h3 className="col-3 text-center klavika">match </h3>
          <h3 className="col-3 text-center klavika">ai pick</h3>
          <h3 className="col-3 text-end klavika">result</h3>
        </div>

        <div className="row">
          {matches.map((match, i) => (
            <>
              <div className='xl:hidden md:hidden'>
                <div className="card card-rbke mb-3 flex-column p-4">
                  <div className='w-full mx-auto font-bold text-base text-center mb-4'>{formatDate(match.MatchDate)}</div>
                  <div className='row'>
                    <div className="col-4 d-flex flex-column text-center align-center">
                      <img
                        src={match.__HomeTeam__.TeamLogoURL}
                        alt={match.__HomeTeam__.TeamName}
                        className="w-full"
                        loading="lazy"
                      />
                      <h4 className="klavika mt-4">
                        {match.__HomeTeam__.TeamName}
                      </h4>
                    </div>
                    <div className="col-4 d-flex flex-column text-center justify-center">
                      <span className='text-2xl font-bold'>VS</span>
                    </div>
                    <div className="col-4 d-flex flex-column text-center align-center">
                      <img
                        src={match.__AwayTeam__.TeamLogoURL}
                        alt={match.__AwayTeam__.TeamName}
                        className="w-full"
                        loading="lazy"
                      />
                      <h4 className="klavika mt-4">
                        {match.__AwayTeam__.TeamName}
                      </h4>
                    </div>
                  </div>
                  <div class="flex justify-center flex-col items-center mt-8">
                    <h1 class="text-yellow-500 text-base font-bold uppercase shadow-lg transform origin-top-left">
                      our pick
                    </h1>
                    <h1 class="text-green-500 text-base font-bold uppercase shadow-lg transform origin-top-left">
                      {match.__prediction__.PredictedOutcome}
                    </h1>
                    <h1 class="text-yellow-500 text-base font-bold uppercase shadow-lg transform origin-top-left">
                      winner
                    </h1>
                    <h1 className={`klavika text-base rbke-aipick ${match.win ? "win" : "lose"}`}>
                          {match.winningteampick || "N/A"}
                    </h1>
                  </div>
                </div>
              </div>
              <div className='hidden lg:block'>
                <div className="card card-rbke mb-3">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="row align-items-center rbke-stamp-row">
                        <h4 className="klavika color-primary col-lg-9 col-md-9 col-sm-12">
                          {formatDate(match.MatchDate)}
                        </h4>
                        <img
                          src={stampRbke}
                          alt="stamp rbke"
                          className="stamp col-lg-3 col-md-3 col-sm-12"
                          lazy="loading"
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="row align-items-center justify-content-center text-center">
                        <img
                          src={match.__HomeTeam__.TeamLogoURL}
                          alt={match.__HomeTeam__.TeamName}
                          className="stamp col-2"
                          loading="lazy"
                        />
                        <h4 className="klavika col-8">
                          {match.__HomeTeam__.TeamName} vs {match.__AwayTeam__.TeamName}
                        </h4>
                        <img
                          src={match.__AwayTeam__.TeamLogoURL}
                          alt={match.__AwayTeam__.TeamName}
                          className="stamp col-2"
                          loading="lazy"
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12 ai-picks">
                      <div className="row align-items-center justify-content-center text-center">
                        <h4 className="klavika ">{match.__prediction__.PredictedOutcome}</h4>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="row align-items-center justify-content-center text-end">
                        <h4 className={`klavika rbke-aipick ${match.win ? "win" : "lose"}`}>
                          {match.winningteampick || "N/A"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </HistoryStyleWrapper>
  );
};

export default History;
