import { createGlobalStyle } from "styled-components";
import Klavika from "../fonts/klavika/KlavikaMedCaps-SC.otf";
import BebasKai from "../fonts/bebaskai/BebasKai.otf";
import GothamBlack from "../fonts/gotham/Gotham-Black.otf";
import GothamBold from "../fonts/gotham/Gotham-Bold.otf";
import GothamBook from "../fonts/gotham/Gotham-Book.otf";
import GothamMedium from "../fonts/gotham/Gotham-Medium.otf";
import GothamRoundedMedium from "../fonts/gotham/Gotham-Rounded-Medium.otf";
const GlobalStyles = createGlobalStyle`
.color-primary {
    color: #48E5CA;
}

.bg-primary {
    background-color: #48E5CA !important;
}

/* BebasKai.css */

@font-face {
    font-family: 'BebasKai';
    src: url(${BebasKai}) format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Gotham.css */

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBlack}) format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBook}) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamRoundedMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
}

/* Klavika.css */
@font-face {
    font-family: 'Klavika';
    src: url(${Klavika}) format('opentype');
    font-weight: normal;
    font-style: normal;
}

::-moz-selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
}
::selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
} 

.gotham {
    font-family: 'Gotham';
}

.bebaskai {
    font-family: 'Bebaskai';
}

.klavika {
    font-family: 'Klavika';
}

html,
body {
    font-size: 16px; 
    vertical-align: baseline;
    font-weight: 500;
    line-height: 1.6;
    font-weight: 500;
    overflow-x: hidden; 
    background-color: #040C12 !important;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0px;
    line-height: 1.8;  
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    line-height: 1.35;
    font-family: 'Bakbak One', cursive;
}

a {
    text-decoration: none !important;
    outline: none;
    transition: all .4s;
}


ul, ol {
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
}

html,
body, p, a{
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
} 


@media (min-width: 1400px){
    .container {
        max-width: 980px !important;
        padding: 0px 20px;
    }
} 

@media only screen and (max-width: 767px) {
    .container {
        max-width: 720px !important;
    }
}

`;

export default GlobalStyles;
