import styled from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/rektbookie_v2.png";

const ABOUTStyleWrapper = styled.section`
  padding-bottom: 100px;
  padding-top: 100px;
  background: url(${sectionBgImage});
  // min-height: 950px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  .rbke-h3 {
    font-size: 2rem;
  }

  .button-rbke {
    border: none;
    background: #48e5ca;
    color: #000;
    border-radius: 4px;
    padding: 3px 1.5rem;
  }

  .headline {
    color: #48e5ca;
    font-size: 120px;
    line-height: 1em;
    text-align: center;
  }

  .content {
    margin-top: 2rem;

    h2 {
      font-size: 40px;
      color: rgb(82, 227, 180);
    }

    h3 {
      color: #fff;
    }
  }

  .bithu_about_content {
    max-width: 900px;
    width: 100%;
    margin: auto;
    position: relative;
  }

  .bithu_about_questions {
    margin-top: 57px;
    position: relative;

    .about_questions {
      position: relative;
      z-index: 1;

      .accordion__item + .accordion__item {
        margin-top: 10px;
      }
    }
  }
  .accordion__item {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
  }
  .accordion__header {
    h5 {
      display: flex;
      align-items: center;
      height: 76px;
      margin-bottom: 0px;
      padding: 0 29px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 24px;
      line-height: 34px;
      text-transform: none;
    }
  }

  .accordion__body {
    p {
      padding: 0px 48px 24px 29px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0px;
      max-width: 100%;
    }
  }

  .about_bg_wattermark {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  @media only screen and (max-width: 991px) {
    .accordion__header {
      h5 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .accordion__header {
      h5 {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .accordion__body {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .content {
      text-align: center;
    }
    button {
      margin: 0 auto;
    }
    .description {
      margin: 1rem auto;
      font-size: 12px;
      max-width: 300px;
    }
    .ball-rbke {
      display: flex;
      justify-content: center;
    }
    .headline {
      font-size: 50px;
    }
    .subtitle {
      font-size: 1rem;
    }
    .accordion__header {
      h5 {
        font-size: 18px;
      }
    }
  }
`;

export default ABOUTStyleWrapper;
