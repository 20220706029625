import styled from "styled-components";
import footerBG from "../../../../assets/images/bg/footer_bg3.jpg";

const FooterStyleWrapper = styled.footer`
  a {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  .social-icon {
    height: 25px;
    padding: 0;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 0;
    cursor: pointer;
    user-select: none;

    &:hover {
      position: relative;
      top: -5px;
    }
  }

  .button-rbke {
    border: none;
    background: #48e5ca;
    color: #000;
    border-radius: 4px;
    padding: 3px 1.5rem;
  }

  p {
    margin-bottom: 0;
  }
  /* footer top  */
  .top-footer-content {
    padding: 70px 0 70px;
    background: #1a1218;
    // background: url(${footerBG});
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    overflow: hidden;

    .footer_image {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 45px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .footer-menu {
      .menu-title {
        color: #48e5ca;
        font-size: 40px;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          line-height: 45px;
          a {
            color: rgba(255, 255, 255, 0.7);
            transition: 0.4s;
          }
        }
      }
    }

    .footer_newsletter {
      .form-box {
        position: relative;
        width: 555px;
      }
      input {
        height: 60px;
        &:focus {
          outline: none;
        }
      }
      input {
        width: 480px;
        background: #fff;
        border: 2px solid #343d40;
        line-height: 60px;
        padding: 0 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .top-footer-content {
      .footer_image {
        margin-bottom: 40px;
        p {
          margin-top: 30px;
        }
      }

      .footer-menu {
        .menu-title {
          margin-bottom: 32px;
        }
      }
    }
  }
  @media only screen and (max-width: 667px) {
    .top-footer-content {
      .footer-menu {
        .menu-title {
          margin-bottom: 32px;
        }
      }

      .footer_newsletter {
        margin-top: 30px;
        padding-right: 50px;
      }
    }
  }
  @media only screen and (max-width: 540px) {
    .description {
      font-size: 12px;
      margin: 2rem 0;
    }
    h3.menu-title.text-uppercase {
      display: none;
    }

    button {
      margin: 0 auto;
    }

    .top-footer-content {
      .footer_newsletter {
        padding-right: 0;
        text-align: center;

        .form-box {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }

      .link-widgets {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .top-footer-content {
      .footer_newsletter {
        padding-right: 0;
      }
    }
  }
`;

export default FooterStyleWrapper;
